<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo_fvl.jpg" alt="" height="23" />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/FoodVilla.jpg"
              style="margin-top: 10px"
              alt=""
              height="60"
            />
          </span>
        </a>

        <a routerLink="" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo_fvl.jpg" alt="" height="23" />
          </span>
          <span class="logo-lg">
            <!-- <h1 class="d-none d-xl-inline-block ms-2" style="color:#ffffff; margin-top:20px;">Food Villa</h1> -->
            <img src="assets/images/logo_fvl.jpg" alt="" height="23" />
          </span>
        </a>
      </div>

      <button
        *ngIf="isShowToggleButton"
        type="button"
        class="btn btn-sm px-3 font-size-16 vertinav-toggle header-item waves-effect"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <button
        *ngIf="isShowToggleButton"
        type="button"
        class="btn btn-sm px-3 font-size-16 horinav-toggle header-item waves-effect waves-light"
        data-bs-toggle="collapse"
        data-bs-target="#topnav-menu-content"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                    <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
                    <span class="mdi mdi-magnify"></span>
                </div>
            </form> -->
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item waves-effect"
          ngbDropdownToggle
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/avatar-4.jpg"
            alt="Header Avatar"
          /> -->
          <span class="d-none d-xl-inline-block ms-1">{{
            displayName
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <!-- <h6 class="dropdown-header">เพชรฤทธิ์ ศรีวงศ์</h6> -->
          <!-- <a
            class="dropdown-item"
            routerLink="/mobile/profile-view"
            routerLinkActive="active"
            ><i
              class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"
            ></i>
            <span class="align-middle" key="t-profile">โปรไฟว์</span></a
          > -->
          <!-- <a class="dropdown-item" routerLink="/mobile/meter-dashboard" routerLinkActive="active"><i
                            class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-profile">ซ่อมบำรุง</span></a> -->
          <!-- <a class="dropdown-item" routerLink="/mobile/shop-list" routerLinkActive="active"><i
                            class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-profile">ร้านค้า</span></a>
                    <a class="dropdown-item" routerLink="/mobile/topup" routerLinkActive="active"><i
                            class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-messages">เติมเงิน</span></a>
                    <a class="dropdown-item" routerLink="/mobile/meter-list" routerLinkActive="active"><i
                            class="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-taskboard">มิเตอร์ไฟฟ้า</span></a>
                    <a class="dropdown-item" routerLink="/mobile/repair-list" routerLinkActive="active"><i
                            class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-help">แจ้งเหตุขัดข้อง</span></a>
                    <a class="dropdown-item" routerLink="/mobile/announce-list" routerLinkActive="active"><i
                            class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-help">แจ้งเตือน</span></a> -->
          <!-- <a class="dropdown-item" routerLink="/mobile/history-list" routerLinkActive="active"><i
                            class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-profile">ประวัติการใช้ไฟฟ้า</span></a> -->

          <div class="dropdown-divider"></div>
          <!-- <a class="dropdown-item" href="#"><i
                            class="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-balance">เงินคงเหลือ :
                            <b>201 บาท</b></span></a> -->
          <!-- <a class="dropdown-item" routerLink="/mobile/profile-edit" routerLinkActive="active"><span
                            class="badge bg-success bg-soft text-success mt-1 float-end">New</span><i
                            class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-settings">แก้ไขข้อมูล</span></a> -->
          <a
            class="dropdown-item"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
            ></i>
            <span class="align-middle" key="t-logout">ออกจากระบบ</span></a
          >
        </div>
      </div>
    </div>
  </div>
</header>
