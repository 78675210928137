import { MenuItem } from './menu.model';

export const MENU_SALE: MenuItem[] = [   
    {
        id: 8,
        label: 'แจ้งซ่อม',
        icon: 'bx bx-wrench',
        link: '/ticket-list',
    },
    {
        id: 10,
        isLayout: true
    }
];

